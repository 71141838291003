<template>
  <div>
    <div
      class="flex flex-col w-screen h-screen bg-loading-image bg-cover items-center content-center justify-center"
    >
      <div class="w-full">
        <div
          class="flex flex-col py-6 px-12 text-center items-center justify-center"
        >
          <h2 class="text-2xl text-brand-purple-500 uppercase font-black">
            <div v-if="error">
              <n-html :text="$t('errors.GENERIC')" />
            </div>
            <div v-else>
              <n-html :text="$t('init.title')" />
            </div>
          </h2>
          <p v-if="!error" class="font-serif p-4">
            <n-html :text="$t('init.subtitle')" />
          </p>
          <div class="w-full md:w-1/4">
            <div class="init-progress" v-if="!error">
              <div class="init-progress-indeterminate"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="absolute right-8 md:right-16 bottom-8 md:bottom-16 flex flex-row"
      id="harmony_logo"
    >
      <div
        class="flex items-center flex-shrink-0 bg-white rounded-3xl px-4 py-6 shadow-lg"
      >
        <img class="w-24 md:w-36" src="/logo/logo-sidebar.png" alt="Workflow" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "pages.init",
  data: () => ({
    error: false
  }),
  props: {
    redirect: {
      type: String,
      required: false,
      default: "/"
    },
    params: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters({
      initialized: "application/isInitialized"
    })
  },
  watch: {
    initialized(n) {
      if (n) {
        this.$store.dispatch("application/setLocale");
        this.$router.push({ path: this.redirect, query: this.params });
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    this.$store.dispatch("application/initialize").catch(() => {
      // When init fails, try it again. The cause may be an expired firebase auth token, which will succeed the second time
      setTimeout(() =>
        this.$store.dispatch("application/initialize").catch(() => {
          this.error = true;
          // Should we change the error message to say we will return to the login page and logout?
          // setTimeout(() => this.$store.dispatch("application/logout"), 5000);
        }, 2000)
      );
    });
  }
};
</script>

<style lang="scss">
@import "../theme/variables";

.init {
  text-align: center;
  padding-top: 125px;
}

.init-logo {
  height: 37px;
}

.init-text {
  top: 20vh;
  text-align: center;
  width: 100%;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@media only screen and (max-width: 768px) {
  .bg-cover {
    background-position: 24% 100%;
  }
  #harmony_logo {
    left: 0;
    right: 0;
    margin: auto;
    width: 125px;
  }
}

.init-progress {
  position: relative;
  height: 5px;
  display: block;
  width: 100%;
  background-color: #d8d8d8;
  border-radius: 5px;
  background-clip: padding-box;
  margin: 2rem 0 1rem 0;
  overflow: hidden;

  .init-progress-indeterminate {
    background-color: theme("colors.brand-purple.500");

    &:before {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
        infinite;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
      animation-delay: 1.15s;
    }
  }
}

@include keyframes(indeterminate) {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@include keyframes(indeterminate-short) {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
</style>
